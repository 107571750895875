<template>
    <div id="app">
        <HeaderMenu />
        <!-- 其他内容 -->
        <router-view/>
    </div>
</template>

<script>
import HeaderMenu from "@/components/common/HeaderMenu.vue";

export default {
    components: {
        HeaderMenu,
    },
};
</script>
