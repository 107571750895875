import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/home'  // 添加重定向
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/HomeView')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutUs.vue')
    },
    {
        path: '/show',
        name: 'show',
        component: () => import('@/views/Shows.vue')
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('@/views/Join.vue')
    },
    {
        path: '/brand',
        name: 'brand',
        component: () => import('@/views/BrandInfo.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router;
