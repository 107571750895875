import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router'
import './assets/styles.css'; // 假设你的字体样式在这个文件中

const app = createApp(App)
app.use(router)

app.use(ElementPlus)
for (let i in ElementPlusIconsVue) {
    app.component(i, ElementPlusIconsVue[i])
}
app.mount('#app')
